<!--
 * @Autor: yzr
 * @Date: 2021-08-02 09:53:50
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-27 17:37:56
 * @Description: 协议文本
-->
<template>
  <el-skeleton :loading='!Object.keys(treetyTxt).length' animated>
    <h1 class="agreementName">{{treetyTxt.agreementName}}</h1>
    <div id='treetyTxt' v-html="treetyTxt.agreementText">{{treetyTxt.agreementText}}</div>
    <p>{{`甲方：${treetyTxt.tenantCode}`}}</p>
    <p>{{`乙方：${treetyTxt.relationDealer}`}}</p>
    <p>{{`丙方：${treetyTxt.relationTerminal}`}}</p>
    <!-- <p>{{`日期：${treetyTxt.signTime || ''}`}}</p> -->
  </el-skeleton>
  <!-- <div>
    <h1 class="agreementName">{{treetyTxt.agreementName}}</h1>
    <div id='treetyTxt' v-html="treetyTxt.agreementText">{{treetyTxt.agreementText}}</div>
    <p>{{`甲方：${treetyTxt.relationDealer || ''}`}}</p>
    <p>{{`乙方：${treetyTxt.relationTerminal || ''}`}}</p>
    <p>{{`日期：${treetyTxt.signTime || ''}`}}</p>
  </div> -->
</template>

<script>
export default {
  name: 'TreetyTxt',
  data() {
    return {
      loading: true,
    };
  },
  props: {
    treetyTxt: Object,
  },
  watch: {
    treetyTxt(v) {
      console.log(v);
      this.loading = false;
    },
  },
};
</script>

<style lang="less" scoped>
.agreementName {
  text-align: center;
  font-size: 20px;
  font-weight: bolder;
}
</style>
