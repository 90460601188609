<!--
 * @Autor: yzr
 * @Date: 2021-10-08 10:22:33
 * @LastEditors: yzr
 * @LastEditTime: 2021-10-08 11:20:44
 * @Description: 分利信息表单
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cps-treeties-share-form',
      buttons: [],
      type: '1',
    };
  },
  methods: {
    // 表单渲染完成后调用
    formComplete() {
      // this.getForm();
    },

    getForm() {
      request.get('/cps/v1/web/agreementReward/findByConditions', { type: this.type }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.setValue({
          ...res.result,
        });
      });
    },
  },
};
</script>
