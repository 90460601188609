<!--
 * @Autor: yzr
 * @Date: 2021-07-20 10:37:32
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-09 15:29:12
 * @Description: 分利协议详情表单
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import Txt from './txt.vue';

formCreate.component('treetyTxt', Txt);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'treety_treeties_add',
      buttonText: {
        submit: '确认',
      },
    };
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'treetyTxt') {
        item.props = {
          ...item.props,
          treetyTxt: {},
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    formComplete() {
      this.getDetailInfo();
    },
    // 表单提交
    submit() {
      // 确认签署
      if (this.formConfig.type === 'sign') {
        request.patch('/cps/v1/agreement/agreement/enable', [this.formConfig.row.id]).then((res) => {
          if (!res.success) return this.$message.error(res.message);
          this.$message.success('签署成功');
          this.$emit('onClose');
          this.$emit('onGetList');
        });
      }

      // 确认打印
      if (this.formConfig.type === 'print') {
        console.log(document.getElementById('treetyTxt'));
      }
    },
    // 获取协议详情
    getDetailInfo() {
      request.get('/cps/v1/agreement/agreement/findById', { id: this.formConfig.row.id }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        if (!res.result) return this.$message.warning('暂无相关协议文本');
        this.getRule('treetyTxt').props.treetyTxt = res.result;
      });
    },
  },
};
</script>
