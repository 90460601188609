<!--
 * @Autor: yzr
 * @Date: 2021-07-27 16:51:47
 * @LastEditors: yzr
 * @LastEditTime: 2021-09-13 13:44:58
 * @Description: 分利详情基础信息 - 签署情况表格
-->
<template>
  <vxe-table :data="tableData">
    <vxe-table-column field="signatory" title="签署方">
      <template v-slot="{ row }">
        <span>{{ atoryName(row) }}</span>
      </template>
    </vxe-table-column>
    <vxe-table-column field="signatoryName" title="签署方名称"></vxe-table-column>
    <vxe-table-column field="signatoryCode" title="客户编码"></vxe-table-column>
    <vxe-table-column field="signDate" title="签署时间"></vxe-table-column>
    <vxe-table-column field="signAccount" title="签署人"></vxe-table-column>
  </vxe-table>
</template>

<script>
import request from '@/found/utils/request';

export default {
  name: 'sign_table',
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
    data: String,
  },
  data() {
    return {
      params: {},
      formConfig: {},
      modalConfig: {},
      atory: [],
    };
  },
  mounted() {
    this.getDict();
  },
  methods: {
    // 获取数据字典
    getDict() {
      // 签署方
      request.post('/mdm/mdmdictdata/list', {
        dictTypeCode: 'Signatory',
      }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.atory = res.result;
      });
    },

    atoryName(row) {
      let atoryName = '';
      const atory = this.atory.find((a) => a.dictCode === row.signatory);
      if (atory) atoryName = atory.dictValue;
      return atoryName;
    },
  },
};
</script>
