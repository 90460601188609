var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-tabs",
        {
          staticClass: "tabs",
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "基础信息", name: "info" } },
            [
              _c("BasicForm", {
                ref: "basicForm",
                attrs: { formConfig: _vm.formConfig },
              }),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "协议文本", name: "txt" } },
            [_c("TreetyTxt", { attrs: { treetyTxt: _vm.treetyTxt } })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "终端分利", name: "terminal" } },
            [_c("Benefit", { ref: "terminal" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "经销商分利", name: "dealer" } },
            [_c("Benefit", { ref: "dealer" })],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "消费者分利", name: "customer" } },
            [_c("Benefit", { ref: "customer" })],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "closemodule" },
        [
          _c(
            "el-button",
            { attrs: { type: "danger" }, on: { click: _vm.onClose } },
            [
              _c("i", { staticClass: "el-icon-close fontstyle" }),
              _vm._v("关闭"),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }