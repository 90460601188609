<!--
 * @Autor: yzr
 * @Date: 2021-10-08 10:30:03
 * @LastEditors: yzr
 * @LastEditTime: 2021-10-08 11:21:56
 * @Description: 分利信息详情 - 消费者 / 经销商 / 终端
-->
<template>
  <div>
    <FormHeader class="form-header" :visible='true' title="分利统计" />
    <BenefitForm ref="benefitForm" :formConfig="{ code:'view' }" />
    <FormHeader class="form-header" :visible='true' title="分利明细" />
    <BenefitTable ref="benefitTable" />
  </div>
</template>

<script>
import FormHeader from '@/found/components/form/components/form_header.vue';
import BenefitForm from './benefit_form.vue';
import BenefitTable from './benefit_list.vue';

export default {
  components: {
    BenefitForm,
    BenefitTable,
    FormHeader,
  },
};
</script>

<style lang="less">
.form-header {
  margin: 18px 0;
}
</style>
