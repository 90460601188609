<!--
 * @Autor: yzr
 * @Date: 2021-07-21 14:53:13
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-19 17:36:45
 * @Description: 分利协议详情
-->
<template>
  <div>
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="基础信息" name="info"><BasicForm ref="basicForm" :formConfig="formConfig" /></el-tab-pane>
      <el-tab-pane label="协议文本" name="txt"><TreetyTxt :treetyTxt="treetyTxt"/></el-tab-pane>
      <el-tab-pane label="终端分利" name="terminal"><Benefit ref="terminal" /></el-tab-pane>
      <el-tab-pane label="经销商分利" name="dealer"><Benefit ref="dealer" /></el-tab-pane>
      <el-tab-pane label="消费者分利" name="customer"><Benefit ref="customer" /></el-tab-pane>
    </el-tabs>
    <div class="closemodule">
      <el-button type="danger" @click="onClose"><i class="el-icon-close fontstyle"></i>关闭</el-button>
    </div>
  </div>
</template>

<script>
import request from '@/found/utils/request';
import BasicForm from './basic_form.vue';
import TreetyTxt from '../form/txt.vue';
import Benefit from './benefit.vue';

export default {
  name: 'detail_form',
  components: {
    BasicForm,
    TreetyTxt,
    Benefit,
  },
  props: {
    formConfig: Object,
  },
  mounted() {
    this.getDetailInfo();
    this.getDetailStatistics();
  },
  data() {
    return {
      activeName: 'info',
      treetyTxt: {},
      // buttons: {
      //   submit: false,
      //   close: true,
      //   submitAudit: false,
      // },
    };
  },
  methods: {
    handleClick(tab, event) {
      if (tab.name === 'dealer') this.setDetail('1', tab.name);
      if (tab.name === 'terminal') this.setDetail('2', tab.name);
      if (tab.name === 'customer') this.setDetail('3', tab.name);
    },

    setDetail(type, name) {
      request.get('/cps/v1/web/agreementReport/findAgreementAndType', { type, code: this.formConfig.agreementCode }).then((res) => {
        if (!res.success) return this.$message.error(res.message);
        this.$refs[name].$refs.benefitForm.setValue({
          ...res.result,
        });
      });
      if (name === 'customer') {
        this.$refs[name].$refs.benefitForm.hiddenFields(true, ['cashTotal', 'costTotal', 'redPacketTotal', 'orderTotal', 'orderAmountTotal']);
      }
      if (name === 'dealer') {
        this.$refs[name].$refs.benefitForm.hiddenFields(true, ['cashTotal', 'redPacketTotal', 'integralTotal', 'orderTotal', 'orderAmountTotal']);
      }
      this.$refs[name].$refs.benefitTable.params.type = type;
      this.$refs[name].$refs.benefitTable.params.code = this.formConfig.agreementCode;
      this.$refs[name].$refs.benefitTable.getList();
    },

    // 获取协议详情
    getDetailInfo() {
      request
        .get('/cps/v1/agreement/agreement/findDetailsById', { id: this.formConfig.id })
        .then((res) => {
          if (!res.success) this.$message.error(res.message);
          console.log('协议详情', res);
          this.$refs.basicForm.basicFormInfo = res.result;
          this.treetyTxt = {
            ...res.result,
            agreementText: res.result.profitAgreementTemplate
              ? res.result.profitAgreementTemplate.agreementText
              : '暂无协议文本',
          };
        });
    },
    // 获取分利统计信息
    getDetailStatistics() {
      request
        .get('/cps/v1/web/agreementReport/findByAgreementCode', { code: this.formConfig.agreementCode })
        .then((res) => {
          if (!res.success) this.$message.error(res.message);
          console.log('统计信息', res);
          this.$refs.basicForm.statistics = res.result;
        });
    },

    onClose() {
      this.$emit('onClose');
    },
  },
};
</script>

<style lang="less" scoped>
.tabs {
  height: 100%;
}
.closemodule{
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 73px;
  padding: 20px;
  border-top: 1px solid #e4e4e4;
  text-align: right;
  .el-button{
    width: 73px;
    height: 32px;
    .fontstyle{
      margin-right: 5px;
    }
  }
}
</style>
