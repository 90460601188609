<!--
 * @Autor: yzr
 * @Date: 2021-07-20 10:37:32
 * @LastEditors: yzr
 * @LastEditTime: 2021-10-08 16:17:10
 * @Description: 分利协议主体列表
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';
import Modal from '@/found/components/modal';
import Form from './form';
import activitForm from './activity/activity';
import DetailForm from './detail/detail_form.vue';

export default {
  name: 'modules',
  extends: TablePage,
  components: {
    Form,
    Modal,
    DetailForm,
    activitForm,
  },
  data() {
    return {
      requestType: 'GET',
      requestUrl: '/cps/v1/agreement/agreement/findByConditions',
      params: {},
      formConfig: {},
      modalConfig: {},
    };
  },
  async created() {
    await this.getConfigList('treety_treeties_list');
    this.getPath();
  },
  mounted() {},
  methods: {
    setColumn(v) {
      const rowData = v;
      if (rowData.field === 'belongTemplate') {
        rowData.className = 'formatter-view';
        rowData.type = 'click';
      }
      return rowData;
    },
    modalClick({ val, row }) {
      this.formName = 'Form';
      this.modalConfig = {
        title: '新增协议',
      };
      switch (val.code) {
        case 'signHeader':
          this.sign();
          break;
        case 'sign':
          this.modalConfig.title = '签署协议';
          this.formConfig.row = row;
          this.formConfig.type = 'sign';
          this.openFull();
          break;
        case 'print':
          this.modalConfig.title = '打印协议';
          this.formConfig.row = row;
          this.formConfig.type = 'print';
          this.openFull();
          break;
        case 'stopHeader':
          this.stop();
          break;
        case 'stopLine':
          this.stop(row.id);
          break;
        case 'view':
          this.modalConfig.title = '分利协议详情';
          this.formName = 'DetailForm';
          this.formConfig.id = row.id;
          this.formConfig.agreementCode = row.agreementCode;
          this.openFull();
          break;
        case 'click':
          this.gotoModule(row);
          break;
        case 'cancel_revoke':
          this.cancelRevoke(row.id);
          break;
        case 'cancel_revoke_header':
          this.cancelRevoke();
          break;
        case 'associated_activity_details':
          this.formName = 'activitForm';

          this.modalConfig = {
            ...this.modalConfig,
            type: 'Modal',
            title: '关联活动明细',
            showFooter: false,
          };
          this.formConfig.row = row;
          this.openModal();
          break;
        default:
          break;
      }
    },
    sign(id) {
      let ids = [];
      if (id) ids = [id];
      else ids = this.selection;
      if (!ids.length) return this.$message.warning('选择需要签署的协议');
      this.$confirm('确认签署协议?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.patch('/cps/v1/agreement/agreement/enable', ids).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        })
        .catch(() => false);
    },
    stop(id) {
      let ids = [];
      if (id) ids = [id];
      else ids = this.selection;
      if (!ids.length) return this.$message.warning('选择需要终止的协议');
      this.$confirm('确认终止协议?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request
            .patch('/cps/v1/agreement/agreement/disable', ids)
            .then((res) => {
              if (res.success) {
                this.$message.success('操作成功');
                this.getList();
              }
            });
        })
        .catch(() => false);
    },
    cancelRevoke(id) {
      let ids = [];
      if (id) ids = [id];
      else ids = this.selection;
      if (!ids.length) return this.$message.warning('选择需要解除终止的协议');
      this.$confirm('确认解除终止协议?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          request.patch('/cps/v1/agreement/agreement/revoke', ids).then((res) => {
            if (res.success) {
              this.$message.success('操作成功');
              this.getList();
            }
          });
        })
        .catch(() => false);
    },
    clickVisible({ val, row }) {
      if (!val.code) return false;
      if (
        val === ''
        && (row.signStatus === '2' && row.agreementStatus !== '3')
      ) {
        return false;
      }
      if (row.agreementStatus === '3' && val.code === 'stopLine') {
        return false;
      }

      if (row.signStatus === '2' && val.code === 'sign') {
        return false;
      }
      // if (row.agreementStatus !== '3' && val.code === 'cancel_revoke') {
      //   return false;
      // }
      // if (
      //   val === 'divider'
      //   && (row.agreementStatus === '3'
      //     || (row.signStatus === '2' && row.agreementStatus !== '3'))
      // ) {
      //   return false;
      // }
      if (
        (row.policyNames[0] === '扫码政策' || row.signStatus !== '2')
        && val.code === 'associated_activity_details'
      ) {
        return false;
      }
      // console.log(val, row.buttons);
      return true;
    },
    // 监听页面跳转
    getPath() {
      if (this.$route.path === '/cps_web/treety/treeties') {
        if (Object.keys(this.$route.query).length !== 0) {
          this.formName = 'DetailForm';
          this.formConfig = {
            id: this.$route.query.id,
          };
          this.openFull();
        }
      }
    },
    // 跳转模板
    gotoModule(row) {
      this.$router.replace({
        name: 'modules',
        path: '/cps_web/treety/modules',
        params: {
          templateCode: row.templateCode,
        },
      });
    },
  },
  watch: {
    $route: 'getPath',
  },
};
</script>
