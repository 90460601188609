<!--
 * @Autor: yzr
 * @Date: 2021-07-27 16:46:59
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2021-10-19 17:43:22
 * @Description: 分利协议详情-基础信息
-->

<script>
import request from '@/found/utils/request';
import Form, { formCreate } from '@/found/components/form';
import SignTable from './sing_table.vue';

formCreate.component('signTable', SignTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      formFunctionCode: 'cps-treeties-basicinfo',
      buttons: [],
      agreementSigns: [],
      basicFormInfo: {},
      statistics: {},
    };
  },
  watch: {
    basicFormInfo(v) {
      this.getRule('signTable').props.tableData = v.agreementSigns;
      this.setValue({
        ...v,
        agreeTime: `${v.profitAgreementTemplate ? v.profitAgreementTemplate.effectiveStartTime : '-'} 至 ${v.profitAgreementTemplate ? v.profitAgreementTemplate.effectiveEndTime : '-'}`,
        signTime: `${v.profitAgreementTemplate ? v.profitAgreementTemplate.signStartTime : '-'} 至 ${v.profitAgreementTemplate ? v.profitAgreementTemplate.signEndTime : '-'}`,
      });
    },
    statistics(v) {
      this.setValue({
        ...v,
      });
    },
  },

  methods: {
    // 设置rule
    setRule(v) {
      const item = v;
      if (item.field === 'signTable') {
        item.props = {
          ...item.props,
          tableData: [],
        };
      }
      return item;
    },
    // 表单渲染完成后调用
    formComplete() {
      this.getRule('signTable').props.tableData = this.basicFormInfo.agreementSigns;
      this.setValue({
        ...this.basicFormInfo,
        agreeTime: `${this.basicFormInfo.profitAgreementTemplate ? this.basicFormInfo.profitAgreementTemplate.effectiveStartTime : '-'} 至 ${this.basicFormInfo.profitAgreementTemplate ? this.basicFormInfo.profitAgreementTemplate.effectiveEndTime : '-'}`,
        signTime: `${this.basicFormInfo.profitAgreementTemplate ? this.basicFormInfo.profitAgreementTemplate.signStartTime : '-'} 至 ${this.basicFormInfo.profitAgreementTemplate ? this.basicFormInfo.profitAgreementTemplate.signEndTime : '-'}`,
      });
      this.setValue({
        ...this.statistics,
      });
    },
    // 表单提交
    submit() {

    },
  },
};
</script>
