var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-skeleton",
    { attrs: { loading: !Object.keys(_vm.treetyTxt).length, animated: "" } },
    [
      _c("h1", { staticClass: "agreementName" }, [
        _vm._v(_vm._s(_vm.treetyTxt.agreementName)),
      ]),
      _c(
        "div",
        {
          attrs: { id: "treetyTxt" },
          domProps: { innerHTML: _vm._s(_vm.treetyTxt.agreementText) },
        },
        [_vm._v(_vm._s(_vm.treetyTxt.agreementText))]
      ),
      _c("p", [_vm._v(_vm._s("甲方：" + _vm.treetyTxt.tenantCode))]),
      _c("p", [_vm._v(_vm._s("乙方：" + _vm.treetyTxt.relationDealer))]),
      _c("p", [_vm._v(_vm._s("丙方：" + _vm.treetyTxt.relationTerminal))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }