var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "activity-form-wrapper" },
    [
      _c(
        "vxe-table",
        {
          ref: "xTable",
          attrs: {
            border: "",
            "show-overflow": "",
            data: _vm.tableData,
            "radio-config": { highlight: true },
          },
          on: { "radio-change": _vm.radioChangeEvent },
        },
        [
          _c("vxe-table-column", {
            attrs: { title: "", width: "50", type: "radio" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "activityName", title: "活动名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "activityCode", title: "活动编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "fineClassName", title: "活动细类" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "activityItemName", title: "活动明细名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "activityItemCode", title: "活动明细编码" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "ladderCode", title: "关联阶梯名称" },
          }),
          _c("vxe-table-column", {
            attrs: { field: "time", title: "活动明细执行时间" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "dialog-footer" }, [
        _c(
          "div",
          [
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-check", type: "primary" },
                on: { click: _vm.onOk },
              },
              [_vm._v("确定")]
            ),
            _c(
              "el-button",
              {
                attrs: { icon: "el-icon-close", type: "danger" },
                on: { click: _vm.close },
              },
              [_vm._v("关闭")]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }