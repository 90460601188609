<!--
 * @Autor: yy
 * @Date: 10:50:23
 * @LastEditors: yy
 * @LastEditTime: 2021-09-09 15:29:12
 * @Description: 活动明细
-->
<template>
  <div class="activity-form-wrapper">
    <vxe-table border show-overflow ref="xTable" :data="tableData" :radio-config="{highlight: true}" @radio-change="radioChangeEvent">
      <vxe-table-column title="" width="50" type="radio"></vxe-table-column>
      <vxe-table-column field="activityName" title="活动名称"></vxe-table-column>
      <vxe-table-column field="activityCode" title="活动编码"></vxe-table-column>
      <vxe-table-column field="fineClassName" title="活动细类"></vxe-table-column>
      <vxe-table-column field="activityItemName" title="活动明细名称"></vxe-table-column>
      <vxe-table-column field="activityItemCode" title="活动明细编码"></vxe-table-column>
      <vxe-table-column field="ladderCode" title="关联阶梯名称"></vxe-table-column>
      <vxe-table-column field="time" title="活动明细执行时间"></vxe-table-column>
    </vxe-table>
    <div class="dialog-footer">
      <div>
        <el-button icon="el-icon-check" type="primary" @click="onOk">确定</el-button>
        <el-button icon="el-icon-close" type="danger" @click="close">关闭</el-button>
      </div>
    </div>
  </div>

</template>

<script>
import request from '@/found/utils/request';
import Form from '@/found/components/form';

export default {
  extends: Form,
  name: 'activitForm',
  components: {},
  data() {
    return {
      tableData: [],
      selectRow: {},
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 表单提交
    onOk() {
      // 确认签署
      request
        .post('/cps/v1/agreement/agreement/relationActivity', {
          agreementCode: this.formConfig.row.agreementCode,
          activityItemCode: this.selectRow.activityItemCode,
          cycle: this.selectRow.cycle,
          honourProductCode: this.selectRow.honourProductCode,
          honourProductPrice: this.selectRow.honourProductPrice,
          applyAmount: this.selectRow.applyAmount,
          displayNum: this.selectRow.displayNum,
        })
        .then((res) => {
          if (res.code === 200) {
            this.$message({
              type: 'success',
              message: '关联成功',
            });
            this.$emit('onClose');
            this.$emit('onGetList');
          }
        });
    },
    close() {
      this.$emit('onClose');
    },
    // 获取数据
    getData() {
      request
        .get('/cps/v1/agreement/activity/findByConditions', {
          agreementId: this.formConfig.row.id,
          templateCode: this.formConfig.row.templateCode,
        })
        .then((res) => {
          if (res.code === 200) {
            this.tableData = res.result.records.map((item) => ({
              ...item,
              time: `${item.startTime}-${item.endTime}`,
            }));
          }
        });
    },
    // 单选事件
    radioChangeEvent({ row }) {
      this.selectRow = row;
    },
  },
};
</script>
