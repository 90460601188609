var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("FormHeader", {
        staticClass: "form-header",
        attrs: { visible: true, title: "分利统计" },
      }),
      _c("BenefitForm", {
        ref: "benefitForm",
        attrs: { formConfig: { code: "view" } },
      }),
      _c("FormHeader", {
        staticClass: "form-header",
        attrs: { visible: true, title: "分利明细" },
      }),
      _c("BenefitTable", { ref: "benefitTable" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }