<!--
 * @Autor: yzr
 * @Date: 2021-10-08 10:22:49
 * @LastEditors: yzr
 * @LastEditTime: 2021-10-08 16:52:37
 * @Description: 分利信息表格
-->
<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  extends: TablePage,

  data() {
    return {
      autoGetList: false,
      isCalculateHeight: false,
      requestType: 'GET',
      requestUrl: '/cps/v1/web/agreementReward/findByConditions',
      params: {
        type: '1',
      },
    };
  },
  async created() {
    await this.getConfigList('cps-treeties-share-table');
  },
};
</script>
